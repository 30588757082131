const sections = document.getElementsByClassName('js__content')

if (sections.length) {
    const height = window.innerHeight
    const nav = document.getElementsByClassName('nav')[0]

    // on scroll do something...
    window.addEventListener('scroll', () => {

        if (sections.length > 1)

            if (sections[0].getBoundingClientRect().top * 3 + height > 0)
                return nav.classList.contains('content')
                    ? nav.classList.remove('content')
                    : ''
            else
                return nav.classList.contains('content')
                    ? ''
                    : nav.classList.add('content')
    })
}
